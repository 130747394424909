import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Collection as CollectionIcon } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Masonry from 'react-masonry-css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Lightbox as YALightbox } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import { DEFAULT_LIST_VIEW_MODE, LIST_VIEW_MODES } from '../../../components/basic/Classes';
import EmptyPanel from '../../../components/basic/EmptyPanel';
import ListModeNavToolbar from '../../../components/basic/ToolbarListModeNav';
import CollectionCard from '../../../components/collections/Card';
import CollectionImageBreadcrump from '../../../components/collections/ImageBreadcrump';
import { GET_PUBLIC_COLLECTION_BY_SLUG } from '../../../components/collections/queries';
import ElementCard from '../../../components/elements/Card';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { renderParentBreadcrumps } from '../../../utils/renderHelper';


const Collection = () => {

  const [collection, setCollection] = useState();
  const [currentListMode, setCurrentListMode] = useLocalStorage('list-view-mode-collection', DEFAULT_LIST_VIEW_MODE);
  const [yaLightboxIndex, setYaLightboxIndex] = useState(-1);
  const location = useLocation();
  const navigate = useNavigate();
  const { collectionSlug } = useParams();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('public.collection.meta-description', { collection: collection?.name }) },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  const [getCollection, queryKing] = useLazyQuery(GET_PUBLIC_COLLECTION_BY_SLUG, {
    variables: { id: collectionSlug },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollection(data.collectionBySlug);
    },
  });

  const openElement = (elementId) => {
    navigate(`/c/${collection.publicSlug || collection.publicPk}/${elementId}`);
  };

  const renderBreadcrump = ({ showImage = true, className = 'mb-4', bsPrefix = 'breadcrumb' }) => {
    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/collections" }}>
          {showImage && <CollectionIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{ maxHeight: '20px', minHeight: '20px' }} />}
          {t('public.collections.home-breadcrump')}
        </Breadcrumb.Item>
        {collection && renderParentBreadcrumps({ collection: collection, showImage: showImage, usePublicAddr: true })}
      </Breadcrumb>
    );
  };

  useEffect(() => {
    if (collectionSlug) {
      getCollection({ variables: { slug: collectionSlug } });
    }
  }, [collectionSlug, getCollection]);

  return (
    <>
      <Helmet title={collection?.name || '...'} meta={metaTags} link={linkTags} />

      <Container>
        <div className="d-flex">
          <div className="flex-grow-1 me-3">
            <h2>{collection?.name || '...'}</h2>
          </div>
          <div className="flex-shrink-0 d-none d-sm-flex">
            <CollectionImageBreadcrump renderParents={false} collection={collection} />
          </div>
        </div>
        <hr className="mt-0 mb-3" />
        {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
        {collection?.description && (
          <div className="mb-5">
            <ReactMarkdown>{collection.description}</ReactMarkdown>
          </div>
        )}
        {collection?.childCount > 0 && (
          <div className="mt-5">
            <h3>{t('public.collection.headline-child-collections')}</h3>
            <Row>
              {collection.children.edges.map((subCollection, index) => (
                <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                  <CollectionCard collection={subCollection.node} usePublicAddr={true} />
                </Col>
              ))}
            </Row>
          </div>
        )}
        {collection?.elementCount > 0 && (
          <div className="mt-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h3 className="mb-2">{t('public.collection.headline-elements')}</h3>
              </div>
              <div className="flex-shrink-0">
                <ListModeNavToolbar loading={queryKing.loading} current={currentListMode} setCurrent={setCurrentListMode} size="lg" className="" linkClassNameAddOn="py-0 px-2" />
              </div>
            </div>
            {currentListMode === LIST_VIEW_MODES.Vertical && (
              <Masonry
                breakpointCols={{ default: 5, 576: 1, 768: 2, 992: 3, 1200: 4 }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {collection.elements.map((element, index) => (
                  <ElementCard
                    collection={collection}
                    element={element}
                    key={index}
                    listViewMode={currentListMode}
                    usePublicAddr={true}
                    setYaLightboxIndex={setYaLightboxIndex}
                    yaLightBoxIndex={index}
                  />
                ))}
              </Masonry>
            )}
            {[LIST_VIEW_MODES.Horizontal, LIST_VIEW_MODES.Square].indexOf(currentListMode) > -1 && (
              <Row>
                {collection.elements.map((element, index) => (
                  <Col xs="12" sm="6" lg="4" xxl="2" className="pb-4" key={index}>
                    <ElementCard
                      collection={collection}
                      element={element}
                      key={index}
                      listViewMode={currentListMode}
                      usePublicAddr={true}
                      setYaLightboxIndex={setYaLightboxIndex}
                      yaLightBoxIndex={index}
                    />
                  </Col>
                ))}
              </Row>
            )}
            {(currentListMode === LIST_VIEW_MODES.ListWithImage || currentListMode === LIST_VIEW_MODES.PlainList) && (
              <Table className="bg-light" hover bordered striped responsive>
                <thead>
                  <tr>
                    {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                      <th className="text-center" width="60">
                        <FontAwesomeIcon icon={faImage} />
                      </th>
                    )}
                    <th>{t('pages.collections.element-table-name')}</th>
                    <th width="150">{t('pages.collections.element-table-type_of')}</th>
                  </tr>
                </thead>
                <tbody>
                  {collection.elements.map((element, index) => (
                    <tr
                      key={index}
                      role="button"
                      onClick={() => openElement(element.pk)}
                    >
                      {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                        <td className="text-center p-1">
                          {element.item.cardImageSquare && <img className="rounded border" width="46" height="46" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.cardImageSquare}`} alt={element.item.name} />}
                          {!element.item.cardImageSquare && element.item.typeOf?.cardImageSquare && <img className="rounded border" width="46" height="46" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.typeOf.cardImageSquare}`} alt={element.item.name} />}
                        </td>
                      )}
                      <td>{element.item.name}</td>
                      <td>{element.item?.typeOf?.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {/*<EndOfSteamHandle loading={queryKing.loading} hasNext={queryKing?.data?.collectionElements?.pageInfo.hasNextPage} />*/}
            <YALightbox
              open={yaLightboxIndex >= 0}
              index={yaLightboxIndex}
              close={() => setYaLightboxIndex(-1)}
              slides={collection?.elements?.map((element, index) => ({
                src: `${process.env.REACT_APP_MEDIA_URL}${element.item.imageFull}`,
                title: element.item.name,
              })) || []}
              plugins={[Captions]}
            />
          </div>
        )}
        {collection?.childCount === 0 && collection?.elementCount === 0 && !queryKing.loading && collection?.description === '' && (
          <div className="p-4">
            <div style={{ marginTop: '100px', marginBottom: '100px' }}>
              <EmptyPanel maximize={false} />
            </div>
          </div>
        )}
      </Container>
    </>
  );
}

export default Collection;
